// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$website-primary: mat.define-palette(mat.$indigo-palette);
$website-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$website-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$website-theme: mat.define-light-theme(
  (
    color: (
      primary: $website-primary,
      accent: $website-accent,
      warn: $website-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($website-theme);

/* You can add global styles to this file, and also import other style files */

* {
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;
}

body {
  margin: 0;
  max-width: 1600px;
  min-width: 300px;
  margin: 0 auto !important;
  float: none !important;
}

.header-margin {
  margin-top: 9.5em;
}

.header-margin-mobile {
  margin-top: 4.4em;
}

// Removes the mat-expansion-panel padding https://stackoverflow.com/a/71023336
.remove-mat-expansion-panel-padding
  .mat-expansion-panel-content
  .mat-expansion-panel-body {
  padding: 0 !important;
  height: unset;
}

.remove-mat-expansion-panel-padding .mat-expansion-panel-header {
  padding: 0 !important;
  padding-right: 0.2em !important;
  height: unset;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #114d80;
}

strong {
  font-weight: bold;
}

/*********************************
Material radio button
*********************************/
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #114d80;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #114d80;
  background-color: #114d80;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #114d80;
}

.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
}

/*********************************
Scrollbars
*********************************/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6edf2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #114d8033;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #114d8080;
}

/*********************************
Mat dialog remove padding
*********************************/

.custom-dialog-container .mat-dialog-container {
  padding: 0;
  overflow: unset;
  border-radius: 8px !important;
}

.dialog-container-border .mat-dialog-container {
  border-radius: 8px !important;
}

.squared-dialog-container .mat-dialog-container {
  border-radius: 0px !important;
}

.swiper-pagination-bullet-active {
  background-color: #114d80 !important;
}

/*********************************
Zoom on mouse over
*********************************/

.ngxImageZoomContainer {
  width: 100% !important;
  height: 100% !important;
}

.ngxImageZoomThumbnail {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
